<template>
  <v-container>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    titleToggle: false,
  }),

  mounted() {
    this.onChangedRoute();
  },

  watch: {
    $route() {
      this.onChangedRoute();
    },
  },

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
